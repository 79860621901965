import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import BoxeAnglaise from '../images/boxe-anglaise.jpeg';
import BoxePiedPoing from '../images/boxe-pied-poing.jpeg';
import CardioBoxing from '../images/cardio-boxing.jpeg';
import './VideoCss.css';
import ReactPlayer from 'react-player'
import HoplaTheme from "../theme";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Speciality } from "../models/Speciality";
import { CoursInfos } from "../components/CoursInfos";
import { HorizontalText } from "../components/HorizontalText";
import Fond from '../images/Fond_Page_Boxe.jpg';

const bubbleStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  borderColor: 'white',
  border: '2px solid white',
  marginTop: '16px',
}

export const BoxePage: React.FC = () => {
  const url = `C:/Users/Mehdi/Documents/HopLa/HopLa.Web/hopLa/src/images/video_boxe.png`;
  const matchDownXs = useMediaQuery(HoplaTheme.breakpoints.down("md"));

  const specialities = useSelector((s: RootState) => s.coaches.specialities);

  const getSpe = (name: string): Speciality | undefined => {
    const f = specialities?.filter(s => s.name == name);
    if (f.length == 0) return undefined;
    return f[0];
  }

  const dots = [
    'Aussi bon pour le corps que pour la tête',
    'Se défouler',
    'Prendre confiance en soi',
    'Amélioration du système cardiovasculaire',
    'Renforcement musculaire',
    'Coordinations'
  ];

  const infos = [
    {
      title: 'Coaching boxe personnalisé à Paris avec Hoplà',
      subTitle: `Vous recherchez des séances de coaching boxe de haute qualité à Paris ? Hoplà est la plateforme idéale
      pour réserver vos séances de coaching en boxe, boxe anglaise, boxe poings pieds et cardio boxing en
      Île-de-France. Nos coachs expérimentés vous accompagnent dans l'apprentissage des techniques de boxe
      et l'amélioration de votre condition physique.`
    },
    {
      title: 'Des séances de boxe adaptées à tous les niveaux',
      subTitle: `Que vous soyez débutant ou pratiquant expérimenté, nos coachs de boxe qualifiés sont là pour vous
      guider. Nous proposons des séances adaptées à tous les niveaux, de la découverte des bases de la boxe à
      l'entraînement intensif. Vous pourrez améliorer votre technique, renforcer votre corps et développer votre
      confiance en vous.`
    },
    {
      title: 'Une variété de disciplines de boxe à découvrir',
      subTitle: `Chez Hoplà, nous vous offrons la possibilité d'explorer différentes disciplines de boxe. Que vous soyez intéressé
      par la boxe anglaise, la boxe poings pieds ou le cardio boxing, nos coachs expérimentés vous enseignent les
      techniques spécifiques à chaque discipline. Vous pourrez ainsi diversifier vos compétences et trouver la
      discipline qui vous convient le mieux.`
    },
    {
      title: 'Un entraînement complet pour améliorer votre condition physique',
      subTitle: `La boxe est un sport qui sollicite l'ensemble du corps, vous permettant de développer votre force, votre endurance et votre agilité. Nos séances de coaching en boxe vous offrent un entraînement complet, combinant
      des exercices de cardio, de renforcement musculaire et de coordination. Vous repousserez vos limites et
      atteindrez de nouveaux niveaux de condition physique`
    },
    {
      title: 'Réservez dès maintenant vos séances de coaching en boxe',
      subTitle: <Typography variant="h5">
        Ne perdez plus de temps, réservez dès maintenant vos séances de coaching en boxe avec Hoplà. Que vous
        souhaitiez pratiquer la boxe pour le plaisir, pour améliorer votre forme physique ou pour vous préparer à la
        compétition, notre équipe de coachs passionnés vous accompagnera dans votre parcours. Réservez dès
        maintenant et découvrez les bienfaits de la boxe.
        <ul>
          <li>Réservez des séances de coaching en boxe, boxe anglaise, boxe poings pieds et cardio boxing</li>
          <li>Entraînez-vous avec des coachs qualifiés et expérimentés</li>
          <li>Découvrez différentes disciplines de boxe et diversifiez vos compétences</li>
          <li>Bénéficiez d'un entraînement complet pour améliorer votre condition physique</li>
          <li>Réservez dès maintenant et embarquez dans votre parcours de boxe avec Hoplà</li>
        </ul>
      </Typography>
    }
  ]

  const cours = [
    {
      title: 'Boxe anglaise',
      subTitle: 'Uniquement avec les poings',
      content:
        <Typography color='black' variant='h5'>
          <Box display='inline' fontWeight='bold'>Jab, Cross, Esquive</Box> Vous apprendrez les techniques de frappe, d’esquive et de
          déplacement en toute sécurité grâce à votre coach. Votre séance de Boxe Anglaise
          est personnalisée en fonction de votre niveau <Box display='inline' fontWeight='bold'>(de débutant à confirmé)</Box> et de vos
          objectifs. Les cours s’adressent <Box display='inline' fontWeight='bold'>aux femmes, aux hommes et aux enfants !</Box>
        </Typography>,
      image: `url(${BoxeAnglaise})`,
      speciality: getSpe('Boxe anglaise'),
      reversed: false
    },
    {
      title: 'Boxe pied poing',
      subTitle: 'Front kick, uppercut !',
      content:
        <Typography color='black' variant='h5'>
          Lors de votre séance, votre coach vous enseignera à utiliser vos jambes et vos poings.
          Pas question de rester statique, vous apprendrez à vous déplacer et vous positionner
          pour <Box display='inline' fontWeight='bold'>une attaque ou une esquive</Box>, toujours en total sécurité grâce à votre coach.
          Votre séance est personnalisée en fonction de votre niveau <Box display='inline' fontWeight='bold'>(de débutant à confirmé)</Box>
          et de vos objectifs. Les cours s’adressent <Box display='inline' fontWeight='bold'>aux femmes, aux hommes et aux enfants !</Box>
        </Typography>,
      image: `url(${BoxePiedPoing})`,
      speciality: getSpe('Boxe pied poing'),
      reversed: true
    },
    {
      title: 'Cardio boxing',
      subTitle: 'De la boxe et bien plus !',
      content:
        <Typography color='black' variant='h5'>
          Apprenez <Box display='inline' fontWeight='bold'>les bases des différents styles de boxe</Box> associés à des exercices de
          <Box display='inline' fontWeight='bold'>cardio training</Box> et de <Box display='inline' fontWeight='bold'>renforcement musculaire</Box>.
          Enfilez vos gants ! <Box display='inline' fontWeight='bold'>Frappe aux
            pattes d’ours, squat jump, burpees, corde à sauter</Box> : transpiration assurée ! Un
          cours adapté à tous les niveaux.
        </Typography>,
      image: `url(${CardioBoxing})`,
      speciality: getSpe('Cardio boxing'),
      reversed: false
    }
  ];

  return (
    <Box>
      <Box sx={{
        background: `url(${Fond})`,
        backgroundRepeat: "no-repeat", backgroundSize: 'cover',
        mt: 10, pt: 15, pb: 10
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: { xs: '10%', sm: '20%' } }}>
          <Box sx={{
            backgroundColor: '#104f5c'
          }}>
            <Grid container>
              {matchDownXs && <Grid item xs={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' mt={5} ml={8}>
                  <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                    BOXE
                  </Typography>
                </Box>
              </Grid>}
              <Grid item lg={5} md={12} sx={{
                background: url,
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
              }}>
                <Box m={8}>
                  <ReactPlayer
                    url='video_boxe.m4v'
                    width='100%'
                    playing
                    height='100%'
                    light={<img src='Video_CardioTraining.JPG' style={{ width: '100%', height: '100%' }} />}
                  />
                </Box>
              </Grid>
              <Grid item lg={7} md={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' my={5} ml={5} mr={15}>
                  {!matchDownXs && <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                    BOXE
                  </Typography>}
                  <Typography variant='h3' color='white' sx={{
                    mt: {
                      md: 10,
                      xs: 0
                    }, mb: 2
                  }}>
                    BÉNÉFICES
                  </Typography>
                  <hr style={{ border: '1px solid white', height: '3px', width: '100%', background: 'white' }} />
                  <Grid container>
                    {dots.map(s =>
                      <>
                        <Grid item xs={2}>
                          <div style={bubbleStyle} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='h5' fontWeight='bold' color='white'>
                            {s}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box sx={{ background: '#1a1a1a', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='h5' my={2} color='white' mx={5}>
          Boxe anglaise • Boxe pied poing • Cardio boxing
        </Typography>
      </Box>

      {
        cours
          .map(c =>
            <CoursInfos
              title={c.title}
              subTitle={c.subTitle}
              content={c.content}
              speciality={c.speciality}
              reversed={c.reversed}
              image={c.image} />
          )
      }

      {
        infos.map((m, i) => (
          <HorizontalText
            title={m.title}
            content={m.subTitle}
            reversed={i % 2 == 1}
          />
        ))
      }

      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Typography color='black' variant="h5" fontWeight='bold' sx={{ width: '80%', pt: 10, pb: 10 }} align='center'>
          Rejoignez-nous dès aujourd'hui et découvrez les avantages d'un coaching en fitness sur mesure avec Hoplà. Transformez votre corps, améliorez votre santé et retrouvez une nouvelle vitalité. Réservez vos séances dès maintenant et faites un pas vers une vie plus saine et plus épanouissante.
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#104f5c' }}>
        <Grid container sx={{ pt: 10, pb: 10 }}>
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-end',
                xs: 'center'
              }
            }}>

              <Button variant='contained' size='large'
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px',
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'
                }}>
                Réserver une séance
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-start',
                xs: 'center'
              }
            }}>

              <Button variant='contained' size='large'
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px',
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'
                }}>
                Nous contacter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
